import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/layout';
import { Header } from '../components/organisms/Header';
import SubpageAbout from '../components/organisms/SubpageAbout';
import { AboutType } from './uslugi/transport';
import innertext from 'innertext';
import SEO from '../components/seo';
import aboutUsImage from '../images/onas.svg';


const AboutUs: React.FC<AboutType> = ({data}) => {
    return ( 
        <Layout>
            <SEO title={data.parent.title} description={innertext(data.parent.content)}/>   
            <Header 
                title={data.parent.title} 
                description={innertext(data.parent.content)}
                headerImage={aboutUsImage}
                headerImageWidth={600}
            />
            <SubpageAbout data={data.children.nodes} paddingBottom={90}/>
			
        </Layout>
     );
}
 
export default AboutUs;


export enum WpLanguageCodeEnum {
  EN
}

export const pageQuery = graphql`
query($language: String!) {
  parent: wpPage(slug: {regex: "/o-nas/"}, language: {slug: {eq: $language}}) {
		title
    content
  }
  children: allWpPage(filter: {wpParent: {node: {slug: {regex: "/o-nas/"}}}, language: {slug: {eq: $language}}}, sort: {fields: menuOrder}) {
		nodes {
      title
      content
    }
  }
  allWpPost(
    sort: {fields: dateGmt, order: DESC}
    filter: {categories: {nodes: {elemMatch: {name: {eq: "News"}}}}}
    limit: 3
  ) {
    nodes {
      title
      dateGmt
      slug
      content
      ...WpPostFeaturedImageOriginal
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}`